'use client'

import { Mulish, Sriracha } from 'next/font/google'
import type { ReactNode } from 'react'
import React from 'react'
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from '@mui/material/styles'

const mulishFont = Mulish({
  subsets: ['latin'],
  display: 'swap',
  weight: ['200', '300', '400', '500', '600', '700', '800', '900', '1000'],
})

const srirachaFont = Sriracha({
  subsets: ['latin'],
  display: 'swap',
  weight: ['400'],
})

const theme = createTheme({
  typography: {
    fontFamily: mulishFont.style.fontFamily,
    fontFamilySriracha: srirachaFont.style.fontFamily,
    h1: {
      fontSize: 62,
      fontWeight: 600,
    },
    h2: {
      fontSize: 40,
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '95%',
    },
    body1: {
      fontSize: 20,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 730,
      md: 900,
      lg: 1328,
      xl: 1536,
    },
  },
  palette: {
    text: {
      primary: '#231F20',
    },
    primary: {
      light: '#FFC001',
      main: '#FFF200',
      dark: '#FAC515',
      contrastText: '#231F20',
    },
    secondary: {
      light: '#00B8CE',
      main: '#50D6E7',
      dark: '#0CA1B4',
      contrastText: '#231F20',
    },
    green: '#A1CC3A',
    purple: '#7F70DE',
  },
})

declare module '@mui/material/styles' {
  interface PaletteOptions {
    green: string
    purple: string
  }
  interface TypographyVariantsOptions {
    fontFamilySriracha: React.CSSProperties['fontFamily']
  }
}

export function ThemeProvider({ children }: { children: ReactNode }) {
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
}
